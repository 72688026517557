/* You can add global styles to this file, and also import other style files */
#loginCard{max-width:350px; 
    margin:4em auto;
}

#changePasswordCard{max-width:350px; 
        margin:4em auto;
}
#title_group_div {
    margin:auto;
}

.align-center {
    text-align: center
}

.button-margin-top {
    margin-top:10px !important;
}
.button-margin-bottom {
    margin-bottom:10px !important;
}
.button-margin-left {
    margin-left:7px !important;
}
.button-margin-top {
    margin-right:7px !important;
}

.fullwidth_form >* {width:100%}



body{margin:0px;background-color:lightgrey;}
