@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$endoo-app-primary: mat-palette($mat-green,800,600,900);
$endoo-app-secondary:  mat-palette($mat-green,A400,A200,A700);

// The warn palette is optional (defaults to red).
$endoo-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$endoo-app-theme: mat-light-theme($endoo-app-primary, $endoo-app-secondary, $endoo-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($endoo-app-theme);
@import url("https://fonts.googleapis.com/icon?family=Material+Icons")